import React from "react";
import styles from "./SolvingVerify.module.scss";

const SolvingVerify = (props) => {
  return (
    <>
      <div className={styles.SolvingVerifyWrap}>
        <div className="SVINContainer">
          {props.data.map((data, index) => {
            return (
              <div key={index}>
                <div className={styles.header}>
                  <h2>{data.Header}</h2>
                </div>
                {data.HRSpringVerifyCard ? (
                  <div className={styles.SolvingVerify}>
                    {data.HRSpringVerifyCard.map((CardContent, i) => (
                      <div className={styles.SolvingVerifyCard} key={i}>
                        <h4>{CardContent.title}</h4>
                        <p>{CardContent.desc}</p>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SolvingVerify;
