import React from "react";
import Header from "../../components/Landers/Header";
import Banner from "../../components/Landers/Banner";
import BrandsTemp from "../../components/Landers/BrandsTemp";
import AllCheck from "../../components/Landers/AllCheck";
import SolvingVerify from "../../components/Landers/SolvingVerify";
import Explore from "../../components/Landers/Explore";
import GetStarted from "../../components/Landers/GetStarted";
import SEO from "../../components/Landers/SEO";
import Testimonials from "../../components/Landers/Testimonials";
import Footer from "../../components/Landers/Footer";
import DataInfo from "../../components/Data";
import styles from "../../components/Landers/landers.module.scss";
import CustomizedSolutions from "../../components/Landers/CustomizedSolutions";

const HRPrefessional = [
  {
    h5: "Scale your hiring without \nbottlenecks",
    p:
      "Optimize your hiring pool and make smart, quick, and reliable decisions. Be it seasonality or unexpected hiring spikes - place candidates where they are in need and be a step ahead of the competition.",
  },
  {
    h5: "Deliver seamless candidate \nexperience",
    p:
      "Our candidate portal is built to provide a seamless experience for your candidates - right from filling forms to tracking progress, and getting in touch with our support team in case of any assistance.",
  },
  {
    h5: "Safeguard brand reputation",
    p:
      "Your employees represent your company’s mission, values, and brand ethos. Fair, inclusive hiring can help you build a company reputation in a longer duration.",
  },
];

const steps = [
  {
    h5: "Request Employment Verification",
    p:
      "Initiate the background check process for a candidate for whom you need to run employment screening.",
  },
  {
    h5: "Run Employment Checks",
    p: `SpringVerify uses Knowledge-Based Authentication (KBA) to verify the candidate’s history of employment,
      designation, duration, and more. It also checks if they are registered with statutory bodies like MCA/ROC, TAN, PF, etc.`,
  },
  {
    h5: "Get real-time updates",
    p:
      "Get notified about your candidate’s verification status with real time updates. Make informed decisions by hiring the right person for the role.",
  },
];

const testimonials = [
  {
    name: "Rupasi M",
    company: "Datarynx Pvt. Ltd.",
    p: `SpringVerify is our go-to app for all our background checks.
			I'm in love with its flexibility, time-bound closures,
			and a support team that is always ready to answer our queries 24x7.`,
  },
  {
    name: "Ravindra M",
    company: "Rupeezen",
    p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
  },
  {
    name: "Harshavardhan N",
    company: "Razorsharp Technologies Pvt. Ltd.",
    p: `SpringVerify is very simple to use. Initiating checks are extremely easy compared to previous product I have used so far. Their candidate and the HR portals are by far the best. Our company relies on SpringVerify everytime we run background checks on candidates. `,
  },
];

export default () => (
  <div className={styles.HumanResource}>
    <SEO
      title="Background Screening Service for Human Resources | SpringVerify India"
      desc="SpringVerify's screening solutions is designed to scale and streamline hiring. HR leaders can now effortlessly mitigate the risk of mishires and save time."
      url="https://in.springverify.com/human-resources/"
      imgurl="https://assets-sv-in-lander.s3.ap-south-1.amazonaws.com/LP/seo/roles/SV-IN+(Human+Resources).jpeg"
    />
    <div className={styles.topSection}>
      <Header />
      <Banner
        h1={`Top HR Leaders \nTrust SpringVerify`}
        p={`We help HR professionals to hire smarter with reliable verification \nsolutions.
        Keep your operations leaner, get candidates onboarded \nfaster, and gain a competitive advantage in hiring.`}
        img="https://assets-sv-in.gumlet.io/LP/HR/HRBanner.png"
      />
    </div>
    <BrandsTemp data={DataInfo.HRBrandsTemp} />
    <AllCheck data={DataInfo.HRAllCheck} />
    <SolvingVerify data={DataInfo.HRSpringVerify} />
    <CustomizedSolutions
      h2={"Catered solutions built for \nHR Professionals"}
      solutions={HRPrefessional}
      path="HR"
    />
    <Explore data={DataInfo.Explore} />
    <Testimonials testimonials={testimonials} path="Address" />
    <GetStarted
      img="HR/HRGetStarted.svg"
      h3={`Build a reliable \nverification process \nwith SpringVerify`}
    />
    <Footer />
  </div>
);
